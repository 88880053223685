import React, { useContext, useEffect, useState } from "react";
import styles from "./WithdrawWalletSection.module.css";
import MyLoader from "../../../components/MyLoader";
import { SlRefresh } from "react-icons/sl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import UserContext from "../../../helpers/Context/user-context";
import {
  AllGameWithdrawAPI,
  APIAllCategoryGames,
  getGameBalance,
  getGameCategories,
} from "../../../helpers/APIs/GameAPIs";

export default function WithdrawWalletSection() {
  const ctx = useContext(UserContext);
  const queryClient = useQueryClient();
  const [allTransferLoader, setAllTransferLoader] = useState(false);
  const [gamesWithdrawError, setGamesWithdrawError] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [gameCategories, setGameCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState({
    status: false,
    id: null,
  });
  const [balance, setBalance] = useState(false);

  const useAllGameswithBalance = (showBalance) => {
    return useQuery(["all games with balance"], () => APIAllCategoryGames(), {
      enabled: showBalance, // Query will be enabled if `enabled` prop is true and `id` is truthy
    });
  };

  const { data: allCategoryGames, isLoading } =
    useAllGameswithBalance(showBalance);

  const allWithdrawMutation = useMutation(
    (array) => {
      return AllGameWithdrawAPI(array);
    },
    {
      onSuccess: (data, variables) => {
        // console.log(data)
        // console.log(data)
        const allGameBalanceData = Object.entries(data[0]);
        // console.log((allGameBalanceData))
        allGameBalanceData.map((item) => {
          // console.log(item)
          item.map((value) => {
            // console.log(value)
            setAllTransferLoader(false);
            if (value?.message === "PENDING_WITHDRAW") {
              setGamesWithdrawError("Sắp rút lui");
            } else if (value?.message === "PENDING_TRANSACTION") {
              setGamesWithdrawError(
                "Không thể chuyển điểm vào trò chơi vì đã có một yêu cầu chuyển điểm đang chờ xử lý."
              );
            } else if (value?.message === "BALANCE_NETWORK_ERROR") {
              setGamesWithdrawError("Lỗi mạng cân bằng");
            } else if (value?.message === "LACK_FUND") {
              setGamesWithdrawError("Thiếu vốn");
            } else if (value?.message === "NETWORK_ERROR") {
              setGamesWithdrawError("Lỗi mạng");
            } else if (value?.message === "ACCOUNT_DOESNT_EXIST") {
              setGamesWithdrawError("Tài khoản không tồn tại");
            } else if (value?.message === "POINTS_TRANSFERRED_SUCCESSFULLY") {
              setBalance(!balance);
              ctx.setUserInfo({
                ...ctx.userInfo,
                balance: data[1],
              });
              const cachedData = queryClient.getQueryData(
                "all games with balance"
              );
              // Assuming cachedData is an array of games with balance
              const updatedData = cachedData.map((game) => {
                const updatedGameItems = game.game_items.map((singleGame) => {
                  const matchingGame = variables.find(
                    (item) => item.game_id === singleGame.id
                  );
                  return {
                    ...singleGame,
                    balance: matchingGame ? 0 : singleGame.balance,
                  };
                });

                return {
                  ...game,
                  game_items: updatedGameItems,
                };
              });
              queryClient.setQueryData("all games with balance", updatedData);
              queryClient.invalidateQueries("all games with balance");
              setAllTransferLoader(false);
              setGamesWithdrawError();
            }
          });
        });
      },
      onError: (error) => {
        console.error("API Error:", error);
        // setLoader(false);
        setAllTransferLoader(false);
      },
    }
  );

  // onTransferEverythingClicked Withdraw
  const onTransferEverythingClicked = async () => {
    const finalBlanceArray = [];
    allCategoryGames?.map((item) => {
      item?.game_items &&
        item?.game_items?.length &&
        item?.game_items?.filter((game) => {
          if (game.balance != "LỖI MẠNG" && +game?.balance > 0) {
            finalBlanceArray.push({ game_id: game.id, amount: game.balance });
          }
          // console.log(finalBlanceArray)
        });
    });
    if (finalBlanceArray?.length) {
      setAllTransferLoader(true);
      allWithdrawMutation.mutate(finalBlanceArray);
      // }
    } else {
      setGamesWithdrawError("Không có gì để rút");
    }
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await getGameCategories();

      setGameCategories(response?.games);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setGameCategories([]);
      console.error("Error fetching categories:", error);
    }
  };

  const mapBalance = (balance, item_id, id) => {
    const updatedCategories = gameCategories.map((category) => {
      if (category.id === item_id) {
        const updatedItems = category.game_items.map((game) => {
          if (game.id === id) {
            return { ...game, balance }; // Add or update the balance key
          }
          return game;
        });
        return { ...category, game_items: updatedItems };
      }
      return category;
    });
    // console.log(updatedCategories);
    setGameCategories(updatedCategories);
  };

  const handleGameBalance = async (game_id, game_platform_id, item_id, id) => {
    setBalanceLoading({
      status: true,
      id: id,
    });
    try {
      const response = await getGameBalance(game_id, game_platform_id);

      // console.log(response);
      setBalanceLoading({
        status: false,
        id: null,
      });

      if (response?.status) {
        mapBalance(response?.balance, item_id, id);
      } else {
        mapBalance(response, item_id, id);
      }
    } catch (error) {
      setBalanceLoading({
        status: false,
        id: null,
      });
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className={styles.walletSection}>
      <div className={styles.gameSectionStyles}>
        {gameCategories && !loading ? (
          gameCategories?.map((item, index) => (
            <div key={index} className={styles.gameSection}>
              {item?.name ? JSON.parse(item?.name)?.en : `-`}

              <div
                className={styles.games}
                style={{
                  backgroundColor:
                    item?.game_items?.length === 0 ? `transparent` : ``,
                }}
              >
                {item?.game_items &&
                  item?.game_items?.map((game, i) => (
                    <div className={styles.singleGameSection} key={i}>
                      <div>{game?.name ? JSON.parse(game?.name)?.en : `-`}</div>

                      {game?.balance !== undefined ? (
                        <div
                          style={{
                            color: "#00C1FE",
                          }}
                        >
                          {game?.balance}
                        </div>
                      ) : balanceLoading?.id === game?.id &&
                        balanceLoading?.status ? (
                        <MyLoader size={12} />
                      ) : (
                        <SlRefresh
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleGameBalance(
                              game?.game_id,
                              game?.game_platform_id,
                              item?.id,
                              game?.id
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MyLoader />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.horizontalLine}></div>

      <button
        onClick={onTransferEverythingClicked}
        disabled={balance == false || allTransferLoader}
        className={styles.buttonStyles}
      >
        Chuyển mọi thứ vào tài khoản chính
      </button>
    </div>
  );
}

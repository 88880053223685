import { useContext, useEffect, useState } from "react";
import { APIGetCompanyBanks } from "../../helpers/APIs/BankAPIs";
import UserContext from "../../helpers/Context/user-context";
import ProfileLayout from "../../layout/ProfileLayout/ProfileLayout";
import BankDetailsSection from "./LeftSection/LeftSection";
import DepositSection from "./RightSection/RightSection";
import PopupErrorModal from "../../components/PopupErrorModal/PopupErrorModal";
import {
  depositAllowed,
  getProviderDetailApi,
} from "../../helpers/APIs/TransactionAPI";
import RightSection from "./RightSection/RightSection";
import LeftSection from "./LeftSection/LeftSection";
import MyLoader from "../../components/MyLoader";
import QRpage from "./QRPage/QRpage";
import { Modal } from "react-bootstrap";

const Deposit = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const isInitalDeposit = params && params.initial;
  const [amount, setAmount] = useState(isInitalDeposit ? "150000" : "");

  const [activeStep, setActiveStep] = useState("step1");
  const [bankListLoading, setBankListLoading] = useState(false);
  const [data, setData] = useState([]);
  const [QRData, setQRData] = useState(null);
  const [selectedBank, setSelectedBank] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [isDeposit, setIsDeposit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [redirectLink, setRedirectLink] = useState("/");
  const [showOkBtn, setShowOkBtn] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [depositPermissionError, setDepositPermissionError] = useState("");
  const [paymentProviders, setPaymentProviders] = useState(null);
  const [selectedProviderDetail, setSelectedProviderDetail] = useState(null);

  useEffect(() => {
    checkDiposetAllow();
  }, []);

  useEffect(() => {
    setSelectedBank(
      selectedProviderDetail?.payment_method_banks?.length > 0
        ? selectedProviderDetail?.payment_method_banks[0]
        : false
    );
  }, [selectedProviderDetail]);

  const providerDetailFunc = async () => {
    setBankListLoading(true);
    const res = await getProviderDetailApi();
    if (res) {
      setPaymentProviders(res);
      setSelectedProviderDetail(
        res?.sort((a, b) => a.sorting_order - b.sorting_order)[0]
      );
    } else {
      setErrorMessage("Đã xảy ra lỗi, vui lòng liên hệ Chăm sóc khách hàng");
    }
    setBankListLoading(false);
  };
  // companybanks, user Data
  useEffect(() => {
    providerDetailFunc();
  }, []);

  // deposit blocked API
  const checkDiposetAllow = async () => {
    try {
      const res = await depositAllowed();
      if (res.status === 200) {
        setIsDeposit(res.data.status);
      }
    } catch (e) {}
  };

  // set block deposit error
  useEffect(() => {
    if (!isDeposit) {
      setErrorModal(true);
      setDepositPermissionError(
        "Tính năng nạp tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ."
      );
      setShowOkBtn(true);
    }
  }, [isDeposit]);

  return (
    // <ProfileLayout title='Deposit'>
    <>
      <div>
        <div style={{ width: "100%", display: "flex" }}>
          {activeStep === "redirectPage" ? (
            <QRpage
              data={data?.payment_url}
              transactionId={data?.transaction_id}
              result={data}
            />
          ) : (
            <RightSection
              setData={setData}
              amount={amount}
              setAmount={setAmount}
              onNextStepClicked={() => setActiveStep(true)}
              selectedBank={selectedBank}
              setSelectedBank={setSelectedBank}
              companyBanks={selectedProviderDetail?.payment_method_banks}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setActiveStep={setActiveStep}
              bankListLoading={bankListLoading}
              QRData={QRData}
              setQRData={setQRData}
              setLoading={setLoading}
              loading={loading}
              activeStep={activeStep}
              paymentProviders={paymentProviders}
              selectedProviderDetail={selectedProviderDetail}
              setSelectedProviderDetail={setSelectedProviderDetail}
            />
          )}

          {loading ? (
            <div style={{ margin: "auto" }}>
              <MyLoader size={30} />
            </div>
          ) : (
            <></>
          )}

          {activeStep === "step2" ? (
            <LeftSection
              amount={amount}
              errorMessage={errorMessage}
              selectedBank={selectedBank}
              data={data}
              QRData={QRData}
              transactionId={QRData?.transaction_id}
              isInitalDeposit={isInitalDeposit}
              activeStep={activeStep}
            />
          ) : (
            <></>
          )}
        </div>
        {errorModal && (
          <PopupErrorModal
            message={depositPermissionError}
            show={errorModal}
            hideModal={() => setErrorModal(false)}
            blockDeposit={!isDeposit}
            modalClose={
              depositPermissionError ===
              "Tính năng nạp tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ."
                ? false
                : true
            }
            isDeposit={isDeposit}
            redirectLink={redirectLink}
            // showOkBtn={showOkBtn}
          />
        )}
      </div>
    </>
    // </ProfileLayout>
  );
};

export default Deposit;

import { useContext, useEffect, useState } from "react";
import styles from "./Transactions.module.css";
import { useLocation } from "react-router-dom";
import { addCommasToNumber } from "../../helpers/NumberHelper";
import { APIGetAllTransactions } from "../../helpers/APIs/TransactionAPI";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MyLoader from "../../components/MyLoader";
import UserContext from "../../helpers/Context/user-context";
import TimeConverter from "../../helpers/TimeConverter";
import SingleTransactionSide from "./SingleTransactionPopup/SingleTransactionSide";
import SingleTransactionPopup from "./SingleTransactionPopup/SingleTransactionSide";

const Transactions = () => {
  const { search } = useLocation();
  const [transections, setTransections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState(false);
  const [activeTab, setActiveTab] = useState(search ? "2" : "1");
  const ctx = useContext(UserContext);
  const [singleTransactionModal, setSingleTransactionModal] = useState(false);
  const [transactionId, setTransactionId] = useState();

  // get transactions
  useEffect(() => {
    transactionsAPI();
  }, []);

  // transaction API
  const transactionsAPI = async () => {
    setLoading(true);
    const res = await APIGetAllTransactions(localStorage.getItem("auth_token"));
    if (res) {
      setTransections(res);
      // setSelectedTransactions(
      //   res.filter(
      //     (t) => t.transaction_purpose === (search ? "withdraw" : "deposit")
      //   )
      // );
    } else {
      setTransections([]);
    }
    setLoading(false);
  };

  // Tabs change
  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
    if (newValue === "1") {
      setSelectedTransactions(
        transections.filter((t) => t.transaction_purpose === "deposit")
      );
    } else {
      setSelectedTransactions(
        transections.filter((t) => t.transaction_purpose === "withdraw")
      );
    }
  };
  return (
    // <ProfileLayout title="Transactions">
    <>
      <div className={styles.transactionWrapper}>
        <div className={styles.transactionContainer}>
          <Tabs
            variant="fullWidth"
            value={activeTab}
            onChange={loading !== true ? handleChange : null}
            sx={{
              "& .MuiTab-root": {
                fontSize: "14px",
                fontWeight: 600,
                padding: 0,
              },
              "& button.Mui-selected": {
                color: "#000",
                background: "#00C1FE 0% 0% no-repeat padding-box",
                borderRadius: "9px",

                // fontSize: "14px",
                // fontWeight: 600,
              },
            }}
            TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
          >
            <Tab label="Nạp Tiền" value="1" />
            <Tab label="Rút Tiền" value="2" />
          </Tabs>
        </div>
        {loading ? (
          <MyLoader margin="20px" />
        ) : transections.length > 0 && selectedTransactions ? (
          <div className={styles.transactionTable}>
            <table>
              <thead>
                <tr>
                  <th>{activeTab === "1" ? "Số Tiền Nạp" : "Số Tiền Rút"}</th>
                  <th>Ngày</th>
                  <th>Trạng thái</th>
                </tr>
              </thead>
              <tbody>
                {selectedTransactions &&
                  selectedTransactions.map((transection) => {
                    return (
                      <tr
                        onClick={() => {
                          if (transection?.is_approved !== 2) {
                            setSingleTransactionModal(true);
                            setTransactionId(transection?.id);
                          }
                        }}
                        style={{
                          cursor:
                            transection?.is_approved !== 2
                              ? "pointer"
                              : "not-allowed",
                        }}
                        key={transection.id}
                      >
                        <td>
                          {transection.transaction_purpose === "deposit"
                            ? "+"
                            : "-"}
                          {addCommasToNumber(transection.transaction_amount)}
                        </td>
                        <td>
                          {transection?.created_at
                            ? TimeConverter(new Date(transection?.created_at))
                            : "Đang tải.."}
                        </td>
                        <td>{TransactionStatusChip(transection)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.noTransaction}>
            <h3>Không có giao dịch</h3>
          </div>
        )}
        {singleTransactionModal && (
          <SingleTransactionPopup
            show={singleTransactionModal}
            hideModal={() => setSingleTransactionModal(false)}
            transactionId={transactionId}
            setSingleTransactionModal={setSingleTransactionModal}
            singleTransactionModal={singleTransactionModal}
          />
        )}
      </div>

      {/* {singleTransactionModal && (
        <SingleTransactionPopup
          show={singleTransactionModal}
          hideModal={() => setSingleTransactionModal(false)}
          transactionId={transactionId}
        />
      )} */}
      {/* </ProfileLayout> */}
    </>
  );
};

export default Transactions;

// ytransaction chip component
const TransactionStatusChip = (transection) => {
  return (
    <span
      className={`${styles.chip} 
      ${
        transection.is_approved === 0
          ? styles.pendingChip
          : transection.is_approved === 1
          ? styles.successChip
          : transection.is_approved === 2
          ? styles.rejectChip
          : transection.is_approved === 3 //processing deposit
          ? styles.processingChip
          : transection.is_approved === 4 //processing withdraw
          ? styles.processingChip
          : styles.rejectChip
      }`}
    >
      {transection.is_approved === 0
        ? "Đang chờ"
        : transection.is_approved === 1
        ? "Đã phê duyệt"
        : transection.is_approved === 2
        ? "Từ chối"
        : transection.is_approved === 3 //processing deposit
        ? "Đang xử lý"
        : transection.is_approved === 4 //processing withdraw
        ? "Đang xử lý"
        : "Từ chối"}
    </span>
  );
};

// check Transaction Status
const checkStatus = (status) => {
  if (status === 0) {
    return "Đang chờ";
  } else if (status === 1) {
    return "Đã phê duyệt";
  } else if (status === 2) {
    return "Từ chối";
  } else if (status === 3) {
    return "Đang xử lý";
  } else if (status === 4) {
    return "Đang xử lý";
  } else {
    return "Từ chối";
  }
};

// transaction status
// STATUS_PENDING = 0;//Đang chờ
// STATUS_SUCCESS = 1;//Đã phê duyệt
// STATUS_REJECT = 2;//Từ chối
// STATUS_PROCESSING = 3;//Đang xử lý(deposit-processing)
// STATUS_PROCESSING = 4;//Đang xử lý(withdraw-processing)

import { useContext, useEffect, useState } from 'react';
import MyLoader from '../../../components/MyLoader'
import UserContext from '../../../helpers/Context/user-context';
import styles from './LeftSection.module.css'
import CopyItem from '../../../components/CopyItem/CopyItem';
import CommaSeperator from '../../../components/CommaSeperator';
import { useNavigate } from 'react-router-dom';
import { GoDownload } from 'react-icons/go';
import { singleTransactionStatusCheck } from '../../../helpers/APIs/TransactionAPI';
import TimeConverter from "../../../helpers/TimeConverter";
import QRCodeComponent from '../QRCodeComponent';


const LeftSection = ({ errorMessage, QRData, transactionId, isInitalDeposit, activeStep }) => {
  const ctx = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const checkTransactionStatus = async () => {
    const res = await singleTransactionStatusCheck(transactionId || QRData?.id);
    if (res.status) {
      navigate('/profile/transactions')
    }
  };

  useEffect(() => {

    checkTransactionStatus();
    const intervalId = setInterval(checkTransactionStatus, 10000);
    return () => clearInterval(intervalId);

  }, []);

  const items = [
    {
      label: "Ngân hàng nạp tiền",
      value: QRData?.bank_name ? QRData?.bank_name : "Đang tải..",
      copy: false,
    },
    {
      label: "Tên tài khoản nhận",
      value: QRData?.bank_account_name ? QRData?.bank_account_name : "Đang tải..",
      copy: false,
      fontSmall: true,
    },
    {
      label: "Số tài khoản",
      value: QRData?.bank_account_number ? QRData?.bank_account_number : "Đang tải..",
      copy: true,
    }
    ,
    {
      label: "Số tiền nạp",
      value: QRData?.amount ? `${CommaSeperator(QRData?.amount)} VND` : "Đang tải..",
      copy: true,
      amount: true
    },




    // { label: "Nội dung chuyển khoản", value:  ctx?.userInfo?.phone ? ctx?.userInfo?.phone : "Đang tải..", copy: true },
    { label: "Ngày", value: QRData ? QRData?.transaction_created_time && TimeConverter(new Date(QRData?.transaction_created_time)) : "Đang tải..", copy: false },

    {
      label: "Nội dung chuyển khoản",
      value: QRData?.note ? QRData?.note : "Đang tải..",
      copy: true,
      redText: true,
    },
  ];
  useEffect(() => {
    checkTransactionStatus();
    const intervalId = setInterval(checkTransactionStatus, 10000);
    return () => clearInterval(intervalId);

  }, []); 

  return (
    <div className={styles.BankDetailsSection}>
      {errorMessage ? (
        <p className={styles.errorMsg}>{errorMessage}</p>
      ) : (
        <div className={styles.companyBankSection}>
          <div className={styles.depositGuide}>
            Để » NẠP TIỀN « Chuyển khoản cho chúng tôi theo thông tin bên dưới
            đây
          </div>
            <QRCodeComponent base64String={QRData?.payment_qr} QRData={QRData} transactionId={QRData?.transaction_id} />


            <div className={styles.DetailContainer}>
              <div className={styles.titleLabel}>Thông tin tiền gửi</div>
              {items && items.map((item) =>
                <div className={styles.dottedWrapper} >
                  {/* <div className={styles.detailWrapper}>
                    <span className={styles.label}>
                      {item.label}
                    </span>
                    <span className={styles.labelDetail} style={{ color: item?.redText ? "#FF5746" : "#F7DF6F" }}>
                      {item.value}
                    </span>
                  </div> */}
                  <CopyItem key={item.label} item={item} showDetail={true} />
                </div>
              )}
            </div>
          <div className={styles.suggestMsg}>
            <p>
              Lưu ý: Điền chính xác mã{" "}
              <strong style={{ color: "#FF0000", fontSize: "15px" }}>
                  "{QRData?.note}"
              </strong>{" "}
              vào mục "Nội dung/Lời nhắn"
            </p>
            <p>
              Thiếu NỘI DUNG CHUYỂN KHOẢN, SV368 sẽ không thể nhận ra khoản
              chuyển từ Quý khách
            </p>
          </div>
            {
              loading ? (
                <div className={styles.loader}>
                  {" "}
                  <MyLoader />
                </div>
              ) : (
                ""
              )
            }

            <div className={styles.completedButton} onClick={() => navigate("/profile/transactions")}>
              {loading
                ? "Đang tải"
                : isInitalDeposit
                  ? "Kích hoạt tài khoản"
                  : "Hoàn Tất"}
            </div>
            
        </div>
      )}
    </div>
  );
};

export default LeftSection;

//   <input
//     type="radio"
//     value={bank}
//     checked={selectedBank && selectedBank.id === bank.id}
//     onChange={() => setSelectedBank(bank)}
//     name={bank}
//   />

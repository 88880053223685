import * as React from "react";
import { Box, MobileStepper, useTheme } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { autoPlay } from "react-swipeable-views-utils";
import image1 from "../../../assets/Banners/image-1.webp";
import image2 from "../../../assets/Banners/image-2.webp";
import image3 from "../../../assets/Banners/image-3.webp";
import image4 from "../../../assets/Banners/image-4.webp";
import image5 from "../../../assets/Banners/image-5.webp";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const images = [
  {
    id: 1,
    src: image1,
    alt: "image 1"
  },
  {
    id: 2,
    src: image2,
    alt: "image 2"
  },
  {
    id: 3,
    src: image3,
    alt: "image 3"
  },
  {
    id: 4,
    src: image4,
    alt: "image 4"
  },
  {
    id: 5,
    src: image5,
    alt: "image 5"
  }
]

export default function TopBanner() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // console.log(activeStep);
  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x" : "x-reverse"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step) => (
          <div key={step.id}>
            <Box
              component="img"
              sx={{
                height: "auto",
                display: "block",
                overflow: "hidden",
                width: "100%",
              }}
              src={step.src}
              alt={step.alt}
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        sx={{
          ".MuiMobileStepper-dots": {
            position: "relative",
            marginTop: "-70px ",
            marginLeft: "auto",
            marginRight: "auto"
          },
          ".MuiMobileStepper-dotActive": {
            backgroundColor: "white",
          },
          ".MuiMobileStepper-dot": {
            width: "12px",
            height: "12px",
            border: "4px double white",
          }
        }}
        style={{ padding: "0 8%" }}
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
      />
    </Box>
  );
}

import style from "../../components/PopularSection/PopularSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Game3 from "../../assets/HomeImages/Section2/Game3.webp";
import Game4 from "../../assets/HomeImages/Section2/Game4.webp";
import Game5 from "../../assets/HomeImages/Section2/Game5.webp";
import Game6 from "../../assets/HomeImages/Section2/Game6.webp";
import Game7 from "../../assets/HomeImages/Section2/Game7.webp";
import Game8 from "../../assets/HomeImages/Section2/Game8.webp";
import { useContext } from "react";
import UserContext from "../../helpers/Context/user-context";

export const PopularSection = () => {
  const ctx = useContext(UserContext);

  const referral = ctx.refCode && ctx.refCode;
  const games = [
    { game_id: 1, image: Game3 },
    { game_id: 2, image: Game4 },
    { game_id: 3, image: Game5 },
    { game_id: 4, image: Game6 },
    { game_id: 5, image: Game7 },
    { game_id: 6, image: Game8 },
  ];

  const gameImgClick = () => {
    window.open(
      ctx.refCode
        ? `https://${referral}.sv368vn.today/register.html`
        : `https://www.sv368.bio/register`,
      "_blank"
    );
  };
  return (
    <div className={style.wrapper}>
      <div className={style.sectionTitle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
          <path d="M319.887 3.00002L317 0.113264L314.113 3.00002L317 5.88677L319.887 3.00002ZM317 2.50002L0.5 2.49999L0.5 3.49999L317 3.50002L317 2.50002Z" fill="white" />
        </svg>

        <div className={style.title}>Phổ Biến</div>

        <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
          <path d="M0.113251 2.99999L3 5.88674L5.88675 2.99999L3 0.113236L0.113251 2.99999ZM319.5 2.50002L3 2.49999L3 3.49999L319.5 3.50002L319.5 2.50002Z" fill="white" />
        </svg>
      </div>
      <div className={style.sectionContent}>
        <div className={style.gameList}>
          {games?.map((game, index) => {
            return (
              <div key={game?.id} className={style.gameItem}>
                <div className={style.imageContainer}>
                  <img
                    className={style.gameImage}
                    src={game?.image}
                    alt={`Game ${game?.id}`}
                    onClick={gameImgClick}
                  />
                  <button className={style.overlayButton}>Chơi ngay</button>
                </div>
              </div>

            );
          })}
        </div>

        {/* <div className={style.gameList}>
          {games.slice(3, 6)?.map((game, index) => {
            return <img key={game?.id} className={style.gameImage} src={game?.image} alt={`Game${game?.id}`} onClick={gameImgClick} />
          })}
                  </div> */}
      </div>
    </div>
  );
};

export default PopularSection;

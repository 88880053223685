import style from "../../components/PromotionSection/PromotionSection.module.css";
import DividerLeft from "../../assets/HomeImages/DividerLeft.webp";
import DividerRight from "../../assets/HomeImages/DividerRight.webp";
import Promotion1 from "../../assets/HomeImages/Section3/Promotion1.webp";
import Promotion2 from "../../assets/HomeImages/Section3/Promotion2.webp";
import Promotion3 from "../../assets/HomeImages/Section3/Promotion3.webp";
import Promotion4 from "../../assets/HomeImages/Section3/Promotion4.webp";
import Promotion5 from "../../assets/HomeImages/Section3/Promotion5.webp";
import Promotion6 from "../../assets/HomeImages/Section3/Promotion6.webp";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../helpers/Context/user-context";
import { promotionsAPI } from "../../helpers/APIs/PromotionsAPI";
import MyLoader from "../MyLoader";
import PromotionPopup from "./PromotionPopup";

export const PromotionSection = () => {
  const [promotionsList, setPromotionsList] = useState();
  const [promoCardModal, setPromoCardModal] = useState(false);
  const [openPromoId, setOpenPromoId] = useState();
  const [error, setError] = useState("");
  const ctx = useContext(UserContext);
  useEffect(() => {
    promotionsListApi();
    // ctx.setWithdrawAmount("");
  }, []);

  // get all promotiosn API
  const promotionsListApi = async () => {
    const res = await promotionsAPI();
    if (res.data?.status === true && res.data?.response?.length > 0) {
      setPromotionsList(res.data?.response);
    } else {
      setError("Không tìm thấy khuyến mãi nào");
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.title}>Khuyến mãi</div>
      <div className={style.sectionContent}>
        <div className={style.promotionList}>
          {error ? (
            <div className={style.error}>{error}</div>
          ) : promotionsList ? (
            promotionsList.map((promo) => {
              return (
                <div
                  className={style.promotionItem}
                  key={promo?.id}
                  onClick={() => {
                    setPromoCardModal(true);
                    setOpenPromoId(promo.id);
                  }}
                >
                  <img
                    className={style.promotionImage}
                    src={promo?.image_desktop}
                    alt={`Promotion${promo?.id}`}
                  />
                  <span className={style.promotionDetailsContainer}>
                    <div className={style.promotionDetails}>
                      <span className={style.promotionTitle}>
                        {promo?.title}
                      </span>
                      <span className={style.promotionDate}>
                        {promo.start_date} - {promo.end_date}
                      </span>
                    </div>
                    <button
                      className={style.promotionButton}
                      style={{ cursor: "pointer" }}
                    >
                      Xem Thêm
                    </button>
                  </span>
                </div>
              );
            })
          ) : (
            <div>
              <MyLoader size={25} />
            </div>
          )}
        </div>
      </div>
      {promoCardModal && (
        <PromotionPopup
          show={promoCardModal}
          hideModal={() => setPromoCardModal(false)}
          promo={promotionsList}
          openPromoId={openPromoId}
        />
      )}
    </div>
  );
};

export default PromotionSection;

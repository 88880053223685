import { useEffect, useState } from "react";
import PopupErrorModal from "../../components/PopupErrorModal/PopupErrorModal";
import ProfileLayout from "../../layout/ProfileLayout/ProfileLayout";
import { withdrawAllowed } from "../../helpers/APIs/WithdrawAPI";
import Step1 from "./Step1/Step1";
import { bankListAPI } from "../../helpers/APIs/BankAPIs";
import AddAccountModal from "../../components/AddAccountModal/AddAccountModal";
import Step2 from "./Step2/Step2";
import styles from "./Withdraw.module.css";
import { Step, StepLabel, Stepper } from "@mui/material";

const steps = ["Số dư", "Chọn ngân hàng"];

const Withdraw = () => {
  const [amount, setAmount] = useState("");
  const [errorModal, setErrorModal] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [withdrawerrorMessage, setWithdrawerrorMessage] = useState("");
  const [isWithdraw, setIsWithdraw] = useState(true);
  const [bankErrorMessage, setBankErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addAccountState, setAddAccountState] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [banks, setBanks] = useState();
  const [bankId, setBankId] = useState();
  const [maxAccounts, setMaxAccounts] = useState();
  const [showOkBtn, setShowOkBtn] = useState(false);
  const [redirectLink, setRedirectLink] = useState("/");
  let minAmount = 150;
  let maxAmount = 100000;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // bank list API

  const bankList = async () => {
    setLoader(true);
    const allBanks = await bankListAPI();
    // console.log(allBanks, bankErrorMessage);
    if (allBanks?.response?.length > 0) {
      setBanks(allBanks?.response);
      setMaxAccounts(allBanks?.max_account_count);
      setSelectedBank(allBanks && allBanks?.response[0]);
      setBankId(allBanks?.response[0] ? allBanks?.response[0].id : null);
      setBankErrorMessage("");
    } else if (allBanks?.response?.length <= 0 || allBanks == null) {
      setBankErrorMessage(
        "Chưa có thông tin ngân hàng. Vui lòng thêm ngân hàng để tiếp tục."
      );
      setBanks([]);
      setSelectedBank([]);
      setBankId(null);
      setMaxAccounts(1);
    }
    setLoader(false);
  };

  const checkWithdrawAllow = async () => {
    try {
      const res = await withdrawAllowed();
      if (res.status === 200) {
        setIsWithdraw(res.data.status);
      }
    } catch (e) {}
  };

  useEffect(() => {
    bankList();
    checkWithdrawAllow();
    if (!isWithdraw) {
      setErrorModal(true);
      setWithdrawerrorMessage(
        // "your withdraw is disabled from admin kindy contact to custum service"
        "Tính năng rút tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ."
      );
      setShowOkBtn(true);
    }
  }, [isWithdraw, addAccountState]);

  return (
    // <ProfileLayout title="Withdraw">
    <div>
      <div className={styles.stepSection}>
        <Stepper style={{ width: "50%" }} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <div className={styles.stepText}>{label}</div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div style={{ display: "flex", gap: "35px", flexDirection: "column" }}>
        {activeStep === 0 ? (
          <Step1
            amount={amount}
            setAmount={setAmount}
            withdrawerrorMessage={withdrawerrorMessage}
            loading={loading}
            setBankId={setBankId}
            banks={banks}
            bankId={bankId}
            bankErrorMessage={bankErrorMessage}
            loader={loader}
            setSelectedBank={setSelectedBank}
            setAddAccountState={setAddAccountState}
            minAmount={minAmount}
            maxAmount={maxAmount}
            maxAccounts={maxAccounts}
            setRedirectLink={setRedirectLink}
            setShowOkBtn={setShowOkBtn}
            handleNext={handleNext}
          />
        ) : (
          <Step2
            amount={amount}
            setAmount={setAmount}
            withdrawerrorMessage={withdrawerrorMessage}
            loading={loading}
            banks={banks}
            selectedBank={selectedBank}
            setWithdrawerrorMessage={setWithdrawerrorMessage}
            setErrorModal={setErrorModal}
            setLoading={setLoading}
            loader={loader}
            minAmount={minAmount}
            maxAmount={maxAmount}
            bankErrorMessage={bankErrorMessage}
            setBankId={setBankId}
            setSelectedBank={setSelectedBank}
            bankId={bankId}
            setAddAccountState={setAddAccountState}
            maxAccounts={maxAccounts}
            setRedirectLink={setRedirectLink}
            setShowOkBtn={setShowOkBtn}
            handleBack={handleBack}
          />
        )}
      </div>
      {errorModal && (
        <PopupErrorModal
          message={withdrawerrorMessage}
          show={errorModal}
          hideModal={() => {
            setErrorModal(false);
          }}
          blockWithdraw={
            withdrawerrorMessage ===
            "Tính năng rút tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ."
              ? true
              : false
          }
          bankId={selectedBank && selectedBank.id}
          transactionAmount={amount}
          bankAccountNumber={selectedBank && selectedBank.account_number}
          isWithdraw={isWithdraw}
          showOkBtn={showOkBtn}
          redirectLink={redirectLink}
        />
      )}
      <AddAccountModal
        show={addAccountState}
        hideModal={() => setAddAccountState(false)}
      />
    </div>
    // </ProfileLayout>
  );
};

export default Withdraw;

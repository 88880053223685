import React, { useContext } from "react";
import styles from "./Step1.module.css";
import StepComponent from "../../../components/StepComponent/StepComponent";
import PointsConverter from "../../../components/PointsConverter/PointsConverter";
import MyLoader from "../../../components/MyLoader";
import TransferPoints from "../../../assets/Transfer Points.webp";
import AddImage from "../../../assets/Add.webp";
import PointsArrow from "../../../assets/PointsArrow.webp";
import InputTooltipV2 from "../../../components/InputTooltipV2/InputTooltipV2";
import CommaSeperator from "../../../components/CommaSeperator";
import UserContext from "../../../helpers/Context/user-context";
import { useNavigate } from "react-router-dom";
import Balance from "../../../assets/BalanceIcon/Balance.webp";
import WithdrawWalletSection from "../walletSection/WithdrawWalletSection";

const Step1 = ({
  amount,
  setAmount,
  withdrawerrorMessage,
  loading,
  banks,
  selectedBank,
  isWithdraw,
  setLoading,
  setWithdrawerrorMessage,
  setErrorModal,
  loader,
  minAmount,
  maxAmount,
  setRedirectLink,
  setShowOkBtn,
  handleNext,
}) => {
  const ctx = useContext(UserContext);
  const navigate = useNavigate();

  const onWithdrawAmountChange = (val) => {
    if (!isNaN(val)) {
      setAmount(val);
    }
  };
  return (
    <div className={styles.SelectBankOverlay}>
      <div className={styles.SelectBankWrapper}>
        {/* <StepComponent image={TransferPoints} step={"Step 1"} title={"Số dư"} /> */}
        <div className={styles.withdrawalContainer}>
          {/* <div className={styles.balanceContainer}>
            <span className={styles.balanceTitle}>Số tiền hiện tại:</span>
            <div className={styles.balanceLatest}>
              <span className={styles.balanceText}>{`${CommaSeperator(
                Number(ctx.userInfo?.balance).toFixed(1)
              )}`}</span>
              <img src={PointsArrow} alt="arrow" />
              <span className={styles.balanceText}>
                đ {`${CommaSeperator(Number(ctx.userInfo?.balance) * 30)}`}
              </span>
            </div>
          </div> */}

          {Number(ctx?.userInfo?.balance) >= 0 && (
            <div className={styles.balanceSection}>
              <img className={styles.iconSize2} src={Balance} alt="Balance" />
              <span className={styles.balanceStyles}>Tổng điểm :</span>
              <span className={styles.balanceAmountStyles}>
                {ctx.userInfo?.balance &&
                  `${CommaSeperator(Number(ctx.userInfo?.balance).toFixed(1))}`}
              </span>
            </div>
          )}

          <div className={styles.inputDeposit}>
            <InputTooltipV2
              amount={amount}
              setAmount={setAmount}
              title={"Số điểm nạp"}
              withdraw={true}
              withdrawMinAmount={minAmount}
              withdrawMaxAmount={maxAmount}
              onAmountChange={onWithdrawAmountChange}
            />
          </div>

          <PointsConverter
            transferPoints={amount * 1000}
            withdraw={true}
            text="Thực tế"
          />
          <div className={styles.minMaxContainer}>
            <div className={styles.minMaxSection}>
              <span className={styles.minMaxText}>Min: </span>
              <span className={styles.minMaxValue}>
                {CommaSeperator(Number(minAmount))}
              </span>
            </div>
            <div className={styles.minMaxSection}>
              <span className={styles.minMaxText}>Max: </span>
              <span className={styles.minMaxValue}>
                {CommaSeperator(Number(maxAmount))}
              </span>
            </div>
          </div>

          <button
            className={`${styles.submitButton} ${
              !amount ||
              amount < minAmount ||
              amount > maxAmount ||
              amount.includes(".")
                ? styles.disabled
                : ""
            }`}
            onClick={handleNext}
            disabled={
              loading ||
              !amount ||
              amount < minAmount ||
              amount > maxAmount ||
              amount.includes(".")
            }
          >
            Tiếp theo
          </button>
        </div>
        <div className={styles.walletContainer}>
          <WithdrawWalletSection />
        </div>
      </div>
    </div>
  );
};

export default Step1;
